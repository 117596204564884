import React from "react"
import "./purpose.scss"
import Layout from "../components/layout"
import CTA from "../components/cta"
import SEO from "../components/seo"

const Purpose = () => {
  const wfData = {
    title:
      "From culture to engagement to personal growth, nobody gets <span>workplace performance</span> like we do. ​",
    desc: 'Businesses invest in a variety of solutions to the human performance issue. Recruitment/selection, physical and mental health, management style, culture, engagement, facilities support, training and development, EAP, IT and much more. \n\nWhy? \n\nThe end-game is always the same - to help employees perform at the highest possible level. \n\nBut this patchwork quilt of support and interventions has a fundamental flaw that guarantees failure – lack of integration. To make matters worse, although each of the parts may individually be in great shape, the total sum of the parts is not. \n\nTruly elite performance, at work or in the sporting arena, is about knowing how it all fits together. \n\nImagine a world where a high-performance culture is matched to a workforce with great mental and physical capabilities, exceptional training and support, together with clear communication, visionary leadership and remarkable facilities … welcome to SHAPE! \n\nTo be honest, we don’t DO all these things, but we do measure them – accurately.  It is this deep understanding of the key drivers of employee performance that allows organisations to bridge the gap between mediocrity and perfection, and with it, happier, healthier, more productive employees who align with corporate values and become the engine room for long term corporate competitiveness. \n\nWant to learn more? <a href="/contact">Contact us now.</a> ',
  }
  return (
    <Layout>
      <SEO title="Workplace Performance" />
      <div className="performance-wrapper">
        <div className="performance-content-wrapper">
          <h1 dangerouslySetInnerHTML={{ __html: wfData.title }} />
          <p dangerouslySetInnerHTML={{ __html: wfData.desc }} />
        </div>
      </div>
    </Layout>
  )
}
export default Purpose
